import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Images
import CircleArrow from 'img/circle-arrow.inline.svg'
import Circle from 'img/circlelist.svg'

// Interface
import { FunctionalitiesProps } from 'components/flex/Functionalities/FunctionalitiesShell'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.grey};
`

const ContainerWrapper = styled.div`
  max-width: 1440px;
`

const Functionalities = styled.div`
  @media (min-width: 992px) {
    margin-top: -3rem !important;
  }

  @media (max-width: 991.98px) {
    margin-top: -1rem !important;
  }
`

const Functionality = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 92px;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.larger};
    line-height: ${({ theme }) => theme.font.size.larger};
  }

  & span {
    color: ${({ theme }) => theme.color.dark};
  }
`

const Icon = styled(Plaatjie)`
  height: 88px;
  width: 88px;
`

const SvgWrapper = styled(motion.div)`
  & svg {
    width: 38px;
    height: 38px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
  }

  & ul {
    list-style: none;
    padding-left: 1rem;
    & li {
      position: relative;
      margin-left: 0.25rem;
      margin-bottom: 1rem;
      font-size: ${({ theme }) => theme.font.size.big};
      &:before {
        position: absolute;
        left: -20px;
        top: 0;
        content: url(${Circle});
        width: 14px;
        height: 14px;
      }
    }
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    position: absolute;
    top: 150px;
    right: 0;
    width: 580px;
    height: 600px;
  }
`

const FunctionalitiesImage: React.FC<FunctionalitiesProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <Section className="mb-lg-5 pb-5">
      <ContainerWrapper className="position-relative mx-auto">
        <div className="container">
          <Functionalities className="position-relative row mb-5 pb-lg-5">
            {fields.functionalities?.map((functionality, index) => (
              <div className="col-lg-4 mb-4" key={index}>
                <NavLink to={functionality?.link?.url || '#'}>
                  <Functionality
                    className="d-flex justify-content-between align-items-center pe-4"
                    onHoverStart={() => setCurrentIndex(index)}
                    onHoverEnd={() => setCurrentIndex(-1)}
                  >
                    <motion.div
                      initial={{ rotate: 0 }}
                      animate={
                        currentIndex === index ? { rotate: 360 } : { rotate: 0 }
                      }
                      transition={{ duration: 0.75 }}
                    >
                      <Icon
                        image={functionality?.icon}
                        alt=""
                        className="me-3"
                      />
                    </motion.div>
                    <div className="d-flex align-items-center">
                      <span className="me-3">{functionality?.link?.title}</span>
                      <SvgWrapper
                        initial={{ x: 0 }}
                        animate={currentIndex === index ? { x: 15 } : { x: 0 }}
                      >
                        <CircleArrow />
                      </SvgWrapper>
                    </div>
                  </Functionality>
                </NavLink>
              </div>
            ))}
          </Functionalities>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-8 col-sm-6">
                  <Content content={fields.description} />
                </div>
                <div className="col-4 col-sm-6">
                  <Image image={fields.image} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Section>
  )
}

export default FunctionalitiesImage
